import React, { Component } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import "./Home.css";
import HomePanel from '../assets/HomePanel.png';
import ContractTrimmed from '../assets/ContractTrimmed.png';
import Installer from '../assets/Installer.png';
import SignUpPic from '../assets/SignUp.png';
import CabinButton from "../components/CabinButton.js"

export default class Home extends Component {
  
  render() {
    return (
      <div className="Home">
        <div className="MainIntro">
          <Row className="IntroContainer">
          
            <Col className="IntroText m-auto" xs={{order: 1}} lg={{order: 2}}>
              <h2 className="largeHeader">Find out how much solar you really need</h2>
              <p className="largeParagraph IntroParagraph">
              Figuring out solar is hard. How many panels do you need? How much will it cost? Does a battery make sense for you? SolarSizeMe gives you information around these questions so you're better prepared when speaking to a solar company. 
              </p>
              <Link to="/sizeme">
              <CabinButton
                text="Size Me"
              />
              </Link>
            </Col>
            <Col className="IntroImageHolder" xs={{order: 2}} lg={{order:1}} >
              <img src={ HomePanel } className="IntroImage" alt="Solar Panel"/>
            </Col>
          </Row>
        </div>
        
        <div className="thirdDiv" id="how-it-works" >
            <h1 className="homeHeader worksHeader">Preparation is Key</h1>
          <Container fluid="true">
          <Row className="thirdDivRow">
            <Col>
              <img src={ SignUpPic } className="worksPic" alt=""/>
              <h5 className="smallHeader rowHeader">Incentive Alignment</h5>
              <p className="smallParagraph rowText">
              Solar companies make more money when they sell you more panels. Find out ahead of time how many you need so you don't pay more than you need to
              </p>
            </Col>
            <Col>
              <img src={ ContractTrimmed } className="worksPic" alt=""/>
              <h5 className="smallHeader rowHeader">Easy to Use</h5>
              <p className="smallParagraph rowText">
              All you need to do is input your zip code and average utility bill - SolarSizeMe does the rest!
              </p>
            </Col>
            <Col>
              <img src={ Installer } className="worksPic" alt=""/>
              <h5 className="smallHeader rowHeader">Battery Analysis</h5>
              <p className="smallParagraph rowText">
              Batteries are becoming increasingly important for home energy needs. SolarSizeMe provides estimates with and without batteries
              </p>
            </Col>
          </Row>
          </Container>
          {/* <div className="FAQText">
          <h5 style={{ textAlign:"center" }}>Still have questions?</h5>
          <Link to="/FAQ">
            <h6 style={{ textAlign:"center" }}>Check Out our FAQ ⟩</h6>
          </Link>
          </div> */}
        </div>
      </div>
    );
  }
}
