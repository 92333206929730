import React from "react";
import Navbar from "react-bootstrap/Navbar";
import "./App.css";
import "./Footer.css"
import Routes from "./Routes";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { onError } from "./lib/errorLib";
import Footer from "./Footer";

function App() {
    return (
      <div className="App container py-3">
        <Navbar collapseOnSelect expand="md" className="mb-3" sticky="top">
          <LinkContainer to="/">
            <Navbar.Brand className="font-weight-bold">
              SolarSizeMe
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle className="navbar-dark" />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="mr-auto navLeft" activeKey={window.location.pathname}>
              <LinkContainer to="/sizeme">
                <Nav.Link>Estimator</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link>About</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes />
        <Footer />
      </div>
    );
}

export default App;
