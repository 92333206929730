import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import "./About.css";

export default class AboutUs extends Component {

  render() {
    return (
      <div className="About">
        <Container>
            <div className="About">
                <p>
                    SolarSizeMe is a tool for finding the optimal number of solar panels for your home.
                    It takes a few inputs, including location, energy usage, and energy storage system attributes (if any)
                    and runs an optimization algorithm to minimize your energy costs. This tool is useful for finding a high-level
                    estimate of your solar array size if you are interested in solar. Unscrupulous solar installers may try to
                    sell you more panels than you need (more panels = more money for them) so hopefully SolarSizeMe is able to
                    provide some peace of mind on your solar journey.
                </p>
            </div>
        </Container>
      </div>
    );
  }
}


