import React from "react";
import { Button } from "react-bootstrap";
import "./CabinButton.css";

export default ({
  text,
  href = "/",
  className = "",
  ...props
}) =>
  <Button
    className={`CabinButton ${className}`}
    {...props}
  >
    {text}
  </Button>;