const config = {
    // Backend config
    // s3: {
    //   REGION: process.env.REACT_APP_REGION,
    //   BUCKET: process.env.REACT_APP_BUCKET,
    // },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://2dpeb3e1gh.execute-api.us-east-1.amazonaws.com/dev",
    },
    // cognito: {
    //   REGION: process.env.REACT_APP_REGION,
    //   USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    //   APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    //   IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    // },
  };
  
  export default config;