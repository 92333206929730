import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Row, Col } from "react-bootstrap"
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import "./Estimator.css";
import { API } from "aws-amplify"

export default function Estimator() {

    
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [address, setAddress] = useState("");
  const [pricePerWatt, setPPW] = useState(3);
  const [monthlyBill, setMonthlyBill] = useState(120);

  const [s_solar_size, set_s_solar_size] = useState("");
  const [s_total_cost, set_s_total_cost] = useState("");
  const [s_solar_produced, set_s_solar_produced] = useState("");
  const [s_yearly_savings, set_s_yearly_savings] = useState("");

  const [b_solar_size, set_b_solar_size] = useState("");
  const [b_total_cost, set_b_total_cost] = useState("");
  const [b_solar_produced, set_b_solar_produced] = useState("");
  const [b_yearly_savings, set_b_yearly_savings] = useState("");


  function validateForm() {
    return address.length > 0 && address.length < 6;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      var ss = await submitOptimizationModel(address, monthlyBill, pricePerWatt);
      console.log(ss)
      setIsLoading(false);
      setHasSubmitted(true);
      set_s_solar_size(ss.solarOnlyModel.solar_size)
      set_s_total_cost(ss.solarOnlyModel.total_cost)
      set_s_solar_produced(ss.solarOnlyModel.yearly_solar_produced)
      set_s_yearly_savings(ss.solarOnlyModel.total_yearly_net)

      set_b_solar_size(ss.batteryModel.solar_size)
      set_b_total_cost(ss.batteryModel.total_cost)
      set_b_solar_produced(ss.batteryModel.yearly_solar_produced)
      set_b_yearly_savings(ss.batteryModel.total_yearly_net)

    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function submitOptimizationModel(address_value, monthly_bill_value, price_per_watt_value) {
    return API.post("SolarSizeMe", "/estimator", {
        body: JSON.stringify({address: address_value, monthly_bill: monthly_bill_value, price_per_watt:price_per_watt_value})
    })
  }

  return (
    <div>
    <Row>
        <Col>
            <h2>Instructions</h2>
            <ol>
                <li>Fill out the fields on the right. Zip code is the only required field, but entering more info gives better results</li>
                <li>Press Calculate!</li>
                <li>Review your results. Results for solar-only and a system including a battery are provided.</li>
            </ol>
        </Col>
        <Col>
    <div className="Estimator">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="sm" controlId="address">
          <Form.Label>Zip Code</Form.Label>
          <Form.Control
            autoFocus
            value={address}
            as="textarea"
            rows={1}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="sm" controlId="monthlyBill">
          <Form.Label>Monthly Bill (dollars per month; default is $120)</Form.Label>
          <Form.Control
            autoFocus
            value={monthlyBill}
            as="textarea"
            rows={1}
            onChange={(e) => setMonthlyBill(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="sm" controlId="pricePerWatt">
          <Form.Label>Price Per Watt for Solar (default is $3)</Form.Label>
          <Form.Control
            autoFocus
            value={pricePerWatt}
            as="textarea"
            rows={1}
            onChange={(e) => setPPW(e.target.value)}
          />
        </Form.Group>
        <LoaderButton
          block="true"
          size="lg"
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Calculate
        </LoaderButton>
      </Form>
      </div>
      </Col>
    </Row>
    <Row>
        <div className="extraSpaceDiv"></div>
        <Col>
            <h2>Solar-Only Model Results</h2>
            <ul>
                <li>Optimum Solar Size (kW): {s_solar_size}</li>
                <li>Estimated Solar Produced Per Year (kWh): {s_solar_produced.toLocaleString(navigator.language)}</li>
                <li>Estimated Energy Savings Per Year ($): {s_yearly_savings.toLocaleString(navigator.language)}</li>
                <li>Total Cost of System ($): {s_total_cost.toLocaleString(navigator.language)}</li>
            </ul>
        </Col>
        <Col>
        <h2>Battery-Included Model Results</h2>
            <ul>
                <li>Optimum Solar Size (kW): {b_solar_size}</li>
                <li>Estimated Solar Produced Per Year (kWh): {b_solar_produced.toLocaleString(navigator.language)}</li>
                <li>Estimated Energy Savings Per Year ($): {b_yearly_savings.toLocaleString(navigator.language)}</li>
                <li>Total Cost of System ($): {b_total_cost.toLocaleString(navigator.language)}</li>
            </ul>
        </Col>
    </Row>
    </div>
  );
}