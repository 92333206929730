import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/Home";
import Estimator from "./containers/Estimator"
import About from "./containers/About"
import NotFound from "./containers/NotFound";

export default function Links() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sizeme" element={<Estimator />} />
      <Route path="/about" element={<About />} />
      <Route path="*" element={<NotFound />} />;
    </Routes>
  );
}