import React from 'react';
import { Container } from 'react-bootstrap';
import "./Footer.css";

function Footer() {
    return (
            <Container className="footerBox" maxWidth={false}>
                <div className="footerDiv">
                    <h1 className="footerText">SolarSizeMe</h1>
                </div>
            </Container>
    );
}

export default Footer;

// <Row>
//                     <Col className="ColHeader">Company</Col>
//                     <Col className="ColHeader">Follow</Col>
//                     <Col className="ColHeader">Resources</Col>
//                 </Row>
//                 <Row>
//                     <Col className="ColItem"><a href="/about-us">About Us</a></Col>
//                     <Col className="ColItem"><a href="https://www.linkedin.com/company/cabin-energy/">LinkedIn</a></Col>
//                     <Col className="ColItem"><a href="/login">Login</a></Col>
//                 </Row>
//                 <Row>
//                     <Col className="ColItem"><a href="/careers">Careers</a></Col>
//                     <Col className="ColItem"><a href="https://twitter.com/CabinEnergy">Twitter</a></Col>
//                     <Col className="ColItem"><a href="/FAQ">FAQ</a></Col>
//                 </Row>
//                 <Row>
//                     <Col className="ColItem"><a href="/privacy-policy">Privacy Policy</a></Col>
//                     <Col className="ColItem"><a href="https://www.instagram.com/cabin_energy/">Instagram</a> & <a href="https://www.facebook.com/CabinEnergy/">Facebook</a></Col>
//                     <Col className="ColItem"><a href="/get-cabin">Get Cabin</a></Col>
//                 </Row>